import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";

class WebsiteDescription extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-web-desc">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col lg="12" className="text-center">
                <h2 className="text-white">Start learning Chinese today</h2>
                <p className="pt-3 home-desc mx-auto">
                  We believe in the product we've built which is why we offer a 14 day trial period with no obligations and no upfront payment details required.
                </p>
                <a href="#pricing">
                  <Button
                    type="button"
                    style={{ color: 'black' }}
                    className="btn btn-white mt-5 waves-effect waves-light"
                  >
                    View Pricing
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDescription;
