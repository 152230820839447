import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SectionTitle from "../common/section-title";
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: '', email: '', subject: '', message: '', currentKey: 1 };
  }

  sendEmail(event) {
    event.preventDefault();
    this.setState({ sending: true })
    console.log(this.state.sending);
    emailjs.send('fluentspeak.email', 'template_uahgo5a', {
      fromName: this.state.name,
      fromEmail: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    }, 'uoqzFQtC7vfRywJNI')
      .then((result) => {
        if (result.text === 'OK') {
          this.setState({ currentKey: this.state.currentKey + 1 })
          this.setState({ name: '', email: '', subject: '', message: '' });
          toast.success('Your message has been successfully sent.', { position: 'top-right' });
          this.setState({ sending: false })
        }
      }, (error) => {
        console.log(error.text);
        toast.error("There was an error sending your message, please try again.", { position: 'top-right' });
        this.setState({ sending: false })
      });
  };

  render() {
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Toaster containerStyle={{ top: '100px' }} />
          <Container>
            <SectionTitle
              title="Get In Touch"
              description="We are always happy to hear from you, whether its good or bad.  If you need to get in contact with us we have a range of digital contact options available, or alternativey you can send us an email using the form below."
            />
            <Row>
              <Col lg="4">
                <div className="mt-4 pt-4">
                  <p className="mt-4">
                    <span className="h5">Office Address:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                      47 Rufous Cct<br />
                      Pallara QLD 4110
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Working Hours:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                      9:00AM To 5:00PM AEST
                    </span>
                  </p>
                </div>
              </Col>
              <Col lg="8">
                <div className="custom-form mt-4 pt-4">
                  <div id="message"></div>
                  <AvForm key={this.state.currentKey} name="contact-form" id="contact-form" onSubmit={(event, errors, values, state) => this.sendEmail(event, errors, values)}>
                    <Row>
                      <Col lg="6">
                        <AvField
                          type="text"
                          className="form-group mt-2"
                          name="name"
                          id="name"
                          placeholder="Your name*"
                          required
                          errorMessage=""
                          onChange={(event) => this.setState({ name: event.target.value })}
                          value={this.state.name}
                          disabled={this.state.sending}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your name",
                            },
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <AvField
                          type="email"
                          className="form-group mt-2"
                          name="email"
                          id="email"
                          placeholder="Your email*"
                          required
                          errorMessage=""
                          onChange={(event) => this.setState({ email: event.target.value })}
                          value={this.state.email}
                          disabled={this.state.sending}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your email",
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <AvField
                          type="text"
                          className="form-group mt-2"
                          name="subject"
                          id="subject"
                          placeholder="Your Subject.."
                          required
                          errorMessage=""
                          value={this.state.subject}
                          disabled={this.state.sending}
                          onChange={(event) => this.setState({ subject: event.target.value })}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your topic",
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="form-group mt-2">
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control"
                            placeholder="Your message..."
                            value={this.state.message}
                            disabled={this.state.sending}
                            onChange={(event) => this.setState({ message: event.target.value })}
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="text-end">
                        <Button className="submitBnt btn btn-primary" disabled={this.state.sending}>
                          Send Message
                        </Button>
                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactUs;