import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Blog Box
import BlogBox from "./blog-box";

class Blog extends Component {
  state = {
    blogs: [
      {
        id: 1,
        image: "assets/images/blog/1.webp",
        topic: "Vocabulary",
        title: "Managing word lists lists",
        description:
          "Review a few tips and tricks on how to best manage your word list for optimal learning.",
        link: "https://help.fluentspeak.app/further-reading/managing-word-lists/",
      },
      {
        id: 2,
        image: "assets/images/blog/identification.webp",
        topic: "Progression",
        title: "Tracking key learning stats",
        description:
          "Learn about some of the built in analytics and how you can use them to track your progress over time.",
        link: "https://help.fluentspeak.app/further-reading/tracking-key-learning-stats/",
      },
      {
        id: 3,
        image: "assets/images/blog/charactersheet.webp",
        topic: "Practice",
        title: "The benefits of pencil & paper",
        description:
          "Checkout some of the benefits you might find by creating your own practice sheets and writing wtih pencil and paper.",
        link: "https://help.fluentspeak.app/further-reading/benefits-of-pencil-and-paper/",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="blog">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Further Reading"
              description="Read through some knowledgebase articles to get a more in-depth look at how Fluentspeak works and how you can maximize your learning potential"
            />

            <Row className="mt-4">
              {/* Render blog boxes */}
              {this.state.blogs.map((blog, key) => (
                <BlogBox key={key} blog={blog} />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
