import React, { Component } from "react";
import { Col } from "reactstrap";

class BlogBox extends Component {
  render() {
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="blog-box mt-4">
            <div>
              <h5 className="mt-4 text-muted">{this.props.blog.topic}</h5>
              <h4 className="mt-3">
                <a className="blog-title" href={this.props.blog.link} target="_blank" rel="noreferrer">
                  {this.props.blog.title}
                </a>
              </h4>
              <p className="mb-0 text-muted">{this.props.blog.description}</p>
              <div className="mb-3">
                <a className="read-btn" href={this.props.blog.link} target="_blank" rel="noreferrer">
                  Read More <i className="mdi mdi-arrow-right"></i>
                </a>
              </div>
              <img
                src={this.props.blog.image}
                className="img-fluid rounded"
                alt=""
              /></div>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default BlogBox;
