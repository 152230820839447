import React, { Component } from 'react';
import { Container, Row, Col, Button } from "reactstrap";

class GetStarted extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section section-lg bg-get-start">
                    <div className="bg-overlay"></div>
                    <Container>
                        <Row>
                            <Col lg={{ size: 8, offset: 2 }} className="text-center">
                                <h1 className="get-started-title text-white">Start Learning Today</h1>
                                <div className="section-title-border mt-4 bg-white"></div>
                                <p className="section-subtitle font-secondary text-white text-center pt-4">With a free 14 day trial period, no credit card details required and no obligations, kickstart your Chinese learning adventure today.</p>
                                <a href="https://portal.fluentspeak.app/signup/" target="_blank" rel="noreferrer">
                                    <Button
                                        style={{ color: 'black' }}
                                        type="button"
                                        className="btn btn-white waves-effect mt-4"
                                    >
                                        Get Started <i className="mdi mdi-arrow-right"></i>
                                    </Button>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default GetStarted;