import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0
    };
  }

  next = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide + 1,
    }));
  };

  prev = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide - 1,
    }));
  };
  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };
  arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 75px)',
    height: '150px',
    cursor: 'pointer',
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px'
  };

  state = {
    members: [
      {
        id: 1,
        name: "Frank Johnson",
        image: "assets/images/team/img-1.jpg",
        post: "CEO",
      },
      {
        id: 2,
        name: "Elaine Stclair",
        image: "assets/images/team/img-2.jpg",
        post: "DESIGNER",
      },
      {
        id: 3,
        name: "Wanda Arthur",
        image: "assets/images/team/img-3.jpg",
        post: "DEVELOPER",
      },
      {
        id: 4,
        name: "Joshua Stemple",
        image: "assets/images/team/img-4.jpg",
        post: "MANAGER",
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <section className="section" id="about">
          <Container>
            <Row> <Col lg={{ size: 10, offset: 1 }}>
              <div className="about-title mx-auto text-center">
                <h2 className="font-weight-light">
                  An ever-growing library of learning components{" "}
                </h2>
                <p className="text-muted pt-4" style={{ fontSize: '20px' }}>
                  Take a scroll through the images below to see some of the available learning components in action.
                </p>
              </div>
            </Col>
            </Row>
            <Row  >
              <Col lg={{ size: 10, offset: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'center', columnGap: '15px' }} className="py-4">
                  <Button
                    title='Previous Image'
                    type="button"
                    style={{ color: 'white', left: '3px', width: '150px', columnGap: '5px', display: 'flex', justifyContent: 'center' }}
                    className="btn btn-primary  waves-effect waves-light"
                    onClick={this.prev}
                  >
                    <i className="mdi mdi-arrow-left"></i><span>Previous</span>
                  </Button>
                  <Button
                    title='Next Image'
                    type="button"
                    style={{ color: 'white', left: '3px', width: '150px', columnGap: '5px', display: 'flex', justifyContent: 'center' }}
                    className="btn btn-primary  waves-effect waves-light"
                    onClick={this.next}
                  >
                    <span>Next</span> <i className="mdi mdi-arrow-right"></i>
                  </Button>
                </div>
                <Carousel
                  autoPlay
                  interval="5000" transitionTime="1000" infiniteLoop
                  style={{ maxHeight: '500px' }}
                  selectedItem={this.state.currentSlide}
                  onChange={this.updateCurrentSlide}
                  {...this.props}
                >
                  <div>
                    <img src="assets/images/slider/dashboard.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/slider/my-word-list.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/slider/karaoke.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/slider/hsk-board.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/slider/articles.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/slider/graded-tasks.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/slider/progression.png" alt="" />
                  </div>
                  <div>
                    <img src="assets/images/slider/vocabulary-lists.png" alt="" />
                  </div>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
