import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col
                lg={{ size: 8, offset: 2 }}
                className="text-white text-center"
              >
                <h4 className="home-small-title">Everybody Learns Differently</h4>
                <h1 className="home-title">
                  Enjoy learning Chinese your way
                </h1>
                <p className="pt-3 home-desc mx-auto">
                  We understand everyone has their own way of learning.  Fluentspeak is here to help you to learn Chinese at your own pace, and have fun while doing it.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
