import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import FooterLinks from "./footer-links";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerItems: [
        {
          title: "Fluentspeak",
          links: [
            { linkTitle: "Home", link: "#" },
            { linkTitle: 'Login', link: 'https://portal.fluentspeak.app/login' },
            { linkTitle: 'Signup', link: 'https://portal.fluentspeak.app/signup' }
          ],
        },
        {
          title: "Legal",
          links: [
            { linkTitle: "Terms & Conditions", link: "https://help.fluentspeak.app/legal/terms-and-conditions/" },
            { linkTitle: 'Privacy Policy', link: 'https://help.fluentspeak.app/legal/privacy-policy/' },
            { linkTitle: 'DMCA Policy', link: 'https://help.fluentspeak.app/legal/dmca-policy/' }
          ],
        },
        {
          title: "Support",
          links: [
            { linkTitle: "FAQ", link: "https://help.fluentspeak.app/guide/FAQ/" },
            { linkTitle: "Contact", link: "https://m.me/Fluentspeak" },
            { linkTitle: "Disscusion", link: "https://www.reddit.com/r/Fluentspeak" },
          ],
        },
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.footerItems.map((item, key) => (
                <Col lg="3" className="mt-4" key={key}>
                  <h4>{item.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {item.links.map((link, key) => (
                        <li key={key}>
                          <a target='_blank' rel="noreferrer" href={link.link}>{link.linkTitle}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </footer>
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
