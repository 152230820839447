import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Practice Sheets",
        icon: "pe-7s-pen",
        description:
          "Create high resolution character sheets so you can practice your handwriting with pencil and paper",
      },
      {
        title: "Entertaining Study",
        icon: "pe-7s-music",
        description:
          "Enjoy learning through several different modes.  Read an article, practice flashcards or even sing karaoke",
      },
      {
        title: "On The Go Learning",
        icon: "pe-7s-phone",
        description:
          "Enjoy studying at your desk, on your laptop in school or even on your mobile phone while sitting on the train"
      },
      {
        title: "New Content Weekly",
        icon: "pe-7s-refresh-cloud",
        description:
          "Expand your language horizons and learn something different with new content added each week"
      },
      
            {
        title: "Useful Statistics",
        icon: "pe-7s-graph",
        description:
          "Get useful statistics at every stage of your learning to see how well you're progressing",
      },
      {
        title: "Friendly Competition",
        icon: "pe-7s-arc",
        description:
          "Compete with friends to see who's currently ahead of the curve (if you're into friendly competition)",
      },
      {
        title: "Tutor Support",
        icon: "pe-7s-study",//pe-7s-headphones
        description:
          "Connect with your tutor and use Fluentspeak together to support your in-person learning",
      },
      // {
      //   title: "Pixel Perfect Design",
      //   icon: "pe-7s-news-paper",
      //   description:
      //     "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
      // },
      // {
      //   title: "Perfect Toolbox",
      //   icon: "pe-7s-tools",
      //   description:
      //     "Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.",
      // },
      // {
      //   title: "Awesome Design",
      //   icon: "pe-7s-timer",
      //   description:
      //     "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.",
      // },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="features">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Features"
              description="Fluentspeak offers a wide range of features to ensure every minute you spend learning is both worthwhile and entertaining."
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
