import React, { Component } from "react";
import { Container, Row } from "reactstrap";

 

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";

class Pricing extends Component {
  state = {
    pricings: [
      // {
      //   id: 1,
      //   title: "Economy",
      //   price: "9.90",
      //   duration: "MONTH",
      //   features: {
      //     bandwith: "1GB",
      //     onlinespace: "50MB",
      //     support: "No",
      //     domain: "1",
      //     hiddenFees: "No",
      //   },
      // },
      {
        id: 2,
        title: "STANDARD",
        price: "7.00",
        duration: "MONTH",
        isRibbon: true,
        features: {
          practiceSheets: "Unlimited",
          spotifySupport: "Yes",
          tutorSupport: "Yes",
          userAnalytics: "Enabled",
          freeTrialPeriod: '14 days',
          hiddenFees: "No",
          inAppPurchases: "No"
        },
      },
      // {
      //   id: 3,
      //   title: "ULTIMATE",
      //   price: "29.90",
      //   duration: "MONTH",
      //   features: {
      //     bandwith: "100GB",
      //     onlinespace: "2GB",
      //     support: "Yes",
      //     domain: "Unlimited",
      //     hiddenFees: "No",
      //   },
      // },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="pricing">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Our Pricing"
              description="At Fluentspeak we don't believe in restricting anyones ability to learn.  Thats why we use a simplified pricing model - one monthly price with access to everything"
            />

            <Row className="mt-5" style={{justifyContent: 'center'}}>
              {/* Render Pricing Box */}
              {this.state.pricings.map((pricing, key) => (
                <PricingBox key={key} pricing={pricing} />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Pricing;
