import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="blurb">
          <Container>
            <Row className="align-items-center">
              <Col lg="5" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>
                    A fresh take on language learning
                  </h3>
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">Focus on content you find entertaining</li>
                    <li className="">Learn at a pace suitable to your busy lifestyle</li>
                    <li className="">Track your progress every step of the way</li>
                    <li className="">Be part of the community and help pave the way for the next generation of learners</li>
                  </ul>
                  <a href="#features">
                    <Button
                      type="button"
                      style={{ color: 'white' }}
                      className="btn btn-primary mt-4 waves-effect waves-light"
                    >
                      Learn More <i className="mdi mdi-arrow-right"></i>
                    </Button>
                  </a>
                </div>
              </Col>
              <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src="assets/images/growth-analytics.svg"
                    alt="macbook"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
