import React, { Component } from "react";
import { Col, Button } from "reactstrap";

class PricingBox extends Component {
  render() {
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="text-center pricing-box bg-white price-active">
            {this.props.pricing.isRibbon ? (
              <div className="ribbon-box">
                <span>Best Value</span>
              </div>
            ) : null}
            <h4 className="text-uppercase">{this.props.pricing.title}</h4>
            <h1>${this.props.pricing.price}</h1>
            <h6 className="text-uppercase text-muted">
              Billing Per {this.props.pricing.duration}
            </h6>
            <div className="plan-features mt-5">
              <p>
                Practice Sheets:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.practiceSheets}
                </b>
              </p>
              <p>
                Spotify Support:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.spotifySupport}*
                </b>
              </p>
              <p>
                Tutor Support:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.tutorSupport}
                </b>
              </p>
              <p>
                User Analytics:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.userAnalytics}
                </b>
              </p>
              <p>
                Free Trial Period:{" "}
                <b className="text-primary">
                  {this.props.pricing.features.freeTrialPeriod}
                </b>
              </p>
              <p>
                <b className="text-primary">
                  {this.props.pricing.features.inAppPurchases}
                </b>{" "}
                In App Purchases
              </p>
              <p>
                <b className="text-primary">
                  {this.props.pricing.features.hiddenFees}
                </b>{" "}
                Hidden Fees
              </p>
            </div>
            <a href="https://portal.fluentspeak.app/signup/" target="_blank" rel="noreferrer">
              <Button
                type="button"
                className="btn btn-primary waves-effect waves-light mt-5"
              >
                Try Now For Free
              </Button>
            </a>
            <p style={{ marginTop: "15px" }}>
              <small>* An active Spotify Premium account is required to listen to music.</small>
            </p>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default PricingBox;
